import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ILogin {
  success: boolean;
  data: {
    token: string;
    name: string;
  };
  message: string;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public _login: ILogin | undefined;
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  logIn(email: string, password: string): Observable<ILogin> {
    return this.http.post<ILogin>(`${this.apiUrl}/login`, { 'email': email, 'password':password }, httpOptions);
  }

}
