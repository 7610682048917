<dx-scroll-view height="100%" width="100%" class="with-footer single-card dx-scrollable dx-scrollview dx-visibility-change-handler dx-scrollable-vertical dx-scrollable-simulated" style="width: 100%; height: 100%;">
    <div class="dx-card content">
        <div class="header">
            <img src="../../../assets/OTD-logo-white.png" width="100px" class="logo">
            <div class="title">Sign In</div>
            <div class="description"></div>
        </div>
        <form class="login-form" (submit)="onSubmit($event)">
            <dx-form id="login-form" [formData]="formData">
                <dxi-item dataField="email" editorType="dxTextBox"
                    [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
                    <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
                    <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                    <dxo-label [visible]="false"></dxo-label>
                </dxi-item>

                <dxi-item dataField="password" editorType="dxTextBox"
                    [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }">
                    <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
                    <dxo-label [visible]="false"></dxo-label>
                </dxi-item>    
                <dxi-item dataField="rememberMe" editorType="dxCheckBox"
                    [editorOptions]="{ text: 'Remember me', elementAttr: { class: '' } }">
                    <dxo-label [visible]="false"></dxo-label>
                </dxi-item>

                <dxi-item itemType="button">
                    <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'SIGN IN'">
                    </dxo-button-options>
                </dxi-item>
            </dx-form>
        </form>
    </div>
</dx-scroll-view>
