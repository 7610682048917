import { Component, OnInit } from '@angular/core';
import { AuthService, ILogin } from '../auth.service';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  loading: boolean = false;
  formData: any = {};
  login: ILogin | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookie: CookieService
  ) { }

  ngOnInit(): void {
    console.log(`referrer: `+window.document.referrer);
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    this.loading = true;

    await this.authService.logIn(email, password).subscribe(result => {
      this.clearCookies();
      this.login = result;
      console.log('Result: ' + JSON.stringify(this.login));
      if(this.login !== undefined && this.login.success === true) {
        console.log('success: '+this.login.success);
        this.cookie.setCookie({name: 'otd-token', value: this.login.data.token, session: true});
        this.cookie.setCookie({name: 'otd-userName', value: this.login.data.name, session: true});
        this.cookie.setCookie({name: 'otd-user', value: JSON.stringify(this.login.data), session: true});
        console.log(JSON.stringify(this.cookie.getCookie('otd-token')));
        this.router.navigate(['/']);
        console.log(`referrer: `+window.document.referrer);
        window.location.href=window.document.referrer;
      }
    }, error => {
      this.loading = false;
      var errorMessage = error.error.message || 'Unable to login. No response from API';
      notify(errorMessage, 'error', 2000);
    });
  }

  clearCookies() {
    this.cookie.deleteCookie('otd-token');
    this.cookie.deleteCookie('otd-userName');
    this.cookie.deleteCookie('otd-user');
    return;
  }

}
